import * as React from 'react';
import {useState} from 'react';
import Button from '../Button/Button';
import {ContactStyles} from './ContactStyles';
import ReCaptcha from '@pittica/gatsby-plugin-recaptcha';


const Contact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setForm(e.target);
  };

  const submit = async (token) => {
    await Promise.resolve(token);
    form.submit();
  };

  return (
    <ContactStyles className="section">
      <form name="contact" method="post" action="/submitContactForm" onSubmit={handleSubmit}>
        <input
          placeholder="Your name..."
          type="text"
          name="name"
          required
        />
        <input
          placeholder="Your email..."
          type="email"
          name="email"
          required
        />
        <textarea
          placeholder="Your message..."
          name="message"
          rows="5"
          required
        ></textarea>
        <span>
          <Button text="Send Message"/>
        </span>
        <span>
          <ReCaptcha
            action="contactForm"
            siteKey="6LdMC7wiAAAAALSTtJKzZdRXc5u4rjbAZ3vNQ91E"
            submitted={submitted}
            onVerify={(token) => submit(token)}
            theme="dark"
            size="invisible"
          />
        </span>
      </form>
    </ContactStyles>
  );
};

export default Contact;
